
<template>
  <div id="canvasCurveDiv" class="canvasCurve" style="overflow-y: auto;">
    <div class='outer' :style="`height:${canvasHeight}px;width:${canvasWidth}px;`">
      <img src="../assets/imgs/grid.png" alt="" :style="`height:${canvasHeight}px;`"/>
      <img class="imgChart arrow-icon" src="../assets/imgs/arrow.png" alt="">
      <!-- 中间点 -->
      <!-- <i class='mid-point'></i> -->
    </div>
  </div>
</template>
<script>
import anime from 'animejs/lib/anime.js';
export default {
  props: ['currentIndex'],
  data() {
    return {
       screenHeight:window.screen.height,
       canvasWidth: 10 / (this.$store.state.sys_param.Dpi * 1 / this.$store.state.sys_param.PPI * 2.45),
       canvasHeight: 10 / (this.$store.state.sys_param.Dpi * 1 / this.$store.state.sys_param.PPI * 2.45),
    }
  },
  mounted() {
    const box1 = document.getElementById("canvasCurveDiv")
    box1.scrollTo((this.canvasWidth - window.screen.width)/2,0);
    box1.style.overflow = 'hidden';
    anime({
      targets: ['.imgChart'],
      translateY: '325',
      // rotate: 360,
      // borderRadius: 100,
      duration: 2000,
      // loop: true,
      endDelay: 1000,
      direction: 'alternate',
      // easing:'
      
    });
  },
  watch: {
    currentIndex: {
      immediate: true,
      handler() {
        var myAnimation = anime({
          targets: ['.imgChart'],
          translateY: '325',
          // rotate: 360,
          // borderRadius: 100,
          duration: 2000,
          // loop: true,
          endDelay: 1000,
          direction: 'alternate'
          // easing:'

        });
      },
    }
  },
  methods: {
  }
};
</script>
<style lang='less' scoped>
@import "../assets/less/vision.less";
</style>